<style lang="scss">
body {
  background: #fff !important;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.card-body {
  padding: 1.5rem !important;
}
#header {
  position: relative !important;
}
.btn-comercio {
  text-decoration: none;
  padding: 7.5px 10px;
  font-weight: 600;
  font-size: 1em;
  color: #ffffff;
  background-color: rgb(0, 38, 230);
  border-radius: 6px;
  width: 100%;
  border: 2px solid #0016b0;
}
.btn-comercio:hover {
  background-color: rgb(64, 204, 255);
  border: 2px solid rgb(64, 204, 255);
}
/*

.form-control {
  height: 45px !important;
  font-size: 16px !important;
}
*/
.title__login {
  color: #21458d;
  font-size: 30px;
  font-weight: 600;
}

.btn__ingresar {
  background: #4b92fc !important;
  border-color: #4b92fc !important;
  height: 45px;
  font-weight: 500 !important;
  font-size: 18px !important;
  letter-spacing: 1px;
}

.avatar__alicia {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 400px !important;
  height: 500px !important;
  transition: .5s;
}

.container__login {
  margin-top: 2%;
  // height: 85vh;
}
.label__input {
  font-size: 17px;
  font-weight: 500;
  color: #000;
}

.p__input {
  margin-bottom: 0px;
  position: relative;
  top: -10px;
  font-size: 13px;
  color: #4d4d4d;
}

.container-links {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 0.9em;
  width: 100%;
  padding-top: 0.5em;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .avatar__alicia {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 300px !important;
    height: 400px !important;
    transition: .5s;
  }
}
//media query para el avatar alicia
@media only screen and (max-width: 991.98px) {
  .avatar__alicia {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 200px !important;
    height: 300px !important;
    transition: .5s;
  }
}

@media only screen and (max-width: 860px) {
  .avatar__alicia {
    display: none;
    transition: .5s;
  }
  /*   position: relative;
    bottom: 0;
    right: -26%;
    width: 100%;
    max-width: 145px;
    margin: 0 auto; */

  .container-links {
    font-size: 0.8em;
    font-weight: bold;
  }
}
</style>


<template>
  <div>
    <header id="header">
      <a href="#hero-section">
        <img src="../../assets/img/logo_negro.png" id="header-img" />
      </a>
      <nav>
        <ul class="mb-0">
          <router-link class="nav-link btn__landing" to="/">
            <img src="../../assets/img/arrow.png" style="width: 45px" />
            <!-- <img src="../../assets/img/icon_exit.png" style="width: 45px" /> -->
          </router-link>
        </ul>
      </nav>
    </header>

    <div class="d-flex align-items-center container__login">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
      ></loading>
      <CContainer fluid>
        <CRow class="justify-content-center">
          <CCol md="4">
            <CCardGroup>
              <CCard class="mx-md-4" style="margin-top: 30%">
                <CCardBody class="p-4" style="border: 1px solid #b7b7b7">
                  <CForm>
                    <h1 class="title__login">Iniciar Sesión</h1>
                    <p
                      v-if="isUsername"
                      style="
                        color: red;
                        font-size: 0.8em;
                        margin: 0 0 5px 0;
                        padding: 0;
                      "
                    >
                      (*)El campo usuario está vacio
                    </p>

                    <label class="label__input">Usuario</label>
                    <CInput
                      placeholder="Usuario"
                      class
                      autocomplete="Ingresa tu usuario"
                      v-model="username"
                      maxlength="30"
                    >
                    </CInput>
                    <p
                      v-if="isPassword"
                      style="
                        color: red;
                        font-size: 0.8em;
                        margin: 0 0 5px 0;
                        padding: 0;
                      "
                    >
                      (*)El campo contraseña está vacio
                    </p>
                    <label class="label__input">Contraseña</label>
                    <!--<p class="p__input">Debe tener almenos 6 caracteres</p>-->
                    <CInput
                      placeholder="Contraseña"
                      type="password"
                      autocomplete="Ingresa tu contraseña"
                      v-model="password"
                      maxlength="20"
                    >
                    </CInput>
                    <CRow>
                      <CCol col="12" class="text-left">
                        <button
                          type="button"
                          class="btn-comercio btn__ingresar"
                          @click="loginUser()"
                        >
                          Ingresar
                        </button>
                      </CCol>
                    </CRow>
                    <CRow>
                      <div class="container-links">
                        <router-link
                          class="nav-link btn__landing"
                          to="/recuperar-password"
                        >
                          ¿Has olvidado tu contraseña?
                        </router-link>
                        <router-link
                          class="nav-link btn__landing"
                          to="/register"
                        >
                          ¿Aún no tienes una cuenta?
                        </router-link>
                      </div>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>

    <img class="avatar__alicia" src="../../assets/img/Alicia_Login.png" />
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      code: "",
      isUsername: false,
      isPassword: false,
      isLoading: false,
      fullPage: true,
    };
  },
  components: {
    Loading,
  },
  methods: {
    loginUser() {
      let vm = this;
      let flagUsername = false;
      let flagPassword = false;

      if (vm.username == "") {
        vm.isUsername = true;
      } else {
        vm.isUsername = false;
        flagUsername = true;
      }

      if (vm.password == "") {
        vm.isPassword = true;
      } else {
        vm.isPassword = false;
        flagPassword = true;
      }

      if (flagUsername == true && flagPassword == true) {
        let data = {
          username: vm.username,
          password: vm.password,
        };
        console.log("ENVIROMENT =>", process.env.VUE_APP_ROOT_API);
        axios.defaults.headers.post["Content-Type"] =
          "application/x-www-form-urlencoded";
        axios
          .post(`${process.env.VUE_APP_ROOT_API}/loginUsuario`, data)
          .then(function (res) {
            vm.isLoading = false;
            if (res.data.data.code == 1) {
              localStorage.setItem(
                `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`,
                "1"
              );
              localStorage.setItem(
                "numeroDocumento",
                res.data.data.numerodocumento
              );
              localStorage.setItem(
                "tipoDocumento",
                res.data.data.tipodocumento
              );
              localStorage.setItem("name", res.data.data.name);
              vm.$router.push("/dashboard");
            } else {
              vm.$fire({
                title: "Seguridad",
                text: "Usuario o Contraseña Incorrecto",
                type: "info",
                timer: 3000,
              }).then((r) => {});

              localStorage.setItem(
                `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`,
                "0"
              );
            }
          })
          .catch((error) => {
            vm.isLoading = false;
            vm.$fire({
              title: "Error",
              text: "Ocurrio un error en el servidor",
              type: "error",
              timer: 4000,
            }).then((r) => {});
          });
      }
    },
    redirectLanding() {
      let vm = this;
      vm.$router.push("/");
    },
  },
  mounted() {
    let vm = this;
    axios.interceptors.request.use(
      (config) => {
        vm.isLoading = true;
        return config;
      },
      (error) => {
        console.log(error);
        return Promise.reject(error);
      }
    );

    $("#pgb-container").hide();

    if (
      localStorage.getItem(
        `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
      ) == "1"
    ) {
      vm.$router.push("/dashboard");
    }
  },
};
</script>