<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Actor&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "RobotoBlack";
  src: url("../../assets/fonts/Roboto-Black.ttf");
}
@font-face {
  font-family: "RobotoBold";
  src: url("../../assets/fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: "RobotoRegular";
  src: url("../../assets/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "RobotoBoldCondensed";
  src: url("../../assets/fonts/Roboto-BoldCondensed.ttf");
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "RobotoRegular", "Actor", sans-serif;
}

body,
html {
  overflow: initial !important;
}

.large {
  font-size: 3rem;
}

.lead {
  font-size: 3rem;
}

.m1 {
  margin: 20px;
}

.m2 {
  margin: 30px;
}

.p1 {
  padding: 20px;
}

.p2 {
  padding: 30px;
}

#header-img {
  margin-left: 30px;
  margin-right: 20px;
  margin-bottom: 0px;
  /* width: 130px; */
  width: 110px;
}

.text-center {
  text-align: center;
  margin: 30px;
}

.btn-news {
  display: inline-block;
  padding: 15px;
  border: none;
  border-radius: 10px;
  color: #fff;
  background: #1c63b8;
  font-size: 1rem;
  outline: none;
  text-decoration: none;
}

#header {
  position: fixed !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  color: #fff !important;
  font-weight: bold;
  background-color: #fff;
  width: 100%;
  height: 10vh;
  z-index: 1;
}

#header > a:hover {
  text-decoration: none;
}

#nav-bar ul {
  display: flex;
  align-items: center;
}

#nav-bar li {
  list-style: none;
}

#nav-bar a {
  color: #fff;
  text-decoration: none;
  margin: 30px 40px;
  letter-spacing: 0.5px;
  font-size: 1.4em;
}

#nav-bar ul li a:hover {
  border-bottom: 3px solid #1c63b8;
  color: #1c63b8;
  font-weight: bold;
}
#nav-bar .navbar-toggler {
  display: none;
}

@media (max-width: 800px) {
  #nav-bar {
    padding: 0;
  }

  #nav-bar ul {
    position: absolute;
    top: 80px;
    left: 0px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;

    width: 100%;

    background-color: #fff;
    /* height: auto; */
    height: 100vh;
    max-height: 0;
    overflow: hidden;
    transition: all ease-in-out 0.3s;
  }
  #nav-bar ul.open {
    max-height: 100vh;
  }
  #nav-bar ul li {
    width: 100%;
    /* border-bottom: 1px solid rgba(255,255,255,0.3); */
  }
  #nav-bar ul li a {
    padding: 0;
  }
  #nav-bar .navbar-toggler {
    display: block;
    position: absolute;

    top: 28px;
    right: 0px;
    background-color: transparent;
    color: #fff;
    border: none;
    /* border-radius: 4px; */
    outline: none;
    padding: 10px 10px;
    cursor: pointer;
  }

  #nav-bar .navbar-toggler span,
  #nav-bar .navbar-toggler span::before,
  #nav-bar .navbar-toggler span::after {
    display: block;
    content: "";
    background-color: #333;
    height: 3px;
    width: 28px;
    border-radius: 4px;
    transition: all ease-in-out 0.3s;
  }
  #nav-bar .navbar-toggler span::before {
    transform: translateY(-8px);
  }
  #nav-bar .navbar-toggler span::after {
    transform: translateY(5px);
  }

  #nav-bar .navbar-toggler.open-navbar-toggler span {
    background-color: transparent;
  }

  #nav-bar .navbar-toggler.open-navbar-toggler span::before {
    transform: translateY(0px) rotate(45deg);
  }
  #nav-bar .navbar-toggler.open-navbar-toggler span::after {
    transform: translateY(-3px) rotate(-45deg);
  }
}

#hero-section {
  height: 100vh;
  /* background-image: url(../../assets/img/avatar.jpg); */
  background-size: 30%60%;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
}

.hero-content {
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  text-align: left;
  padding-left: 50px;
  font-size: 1.4em;
  line-height: 1.5;
  color: #fff;
  /* Overlay */
  background: rgba(0, 0, 0, 0.1);
}

.hero-content h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}
.hero-content p {
  max-width: 900px;
  margin-bottom: 30px;
}
.btns {
  margin-left: 30px;
}
.btn {
  padding: 15px;
  margin-top: 50px;
  border: none;
  border-radius: 10px;
  color: #fff;
  background: #1c63b8;
  font-size: 1rem;
  outline: none;
  text-decoration: none;
  margin-right: 30px;
}
.btn-secondary {
  padding: 15px;
  margin-top: 50px;
  border-radius: 10px;
  color: #fff;
  background: #429ffd;
  font-size: 1rem;
  outline: none;
  text-decoration: none;
}

.newsletter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 160px;
  width: 100%;
  background: #f4f4f4;
}

.newsletter h3 {
  margin: 10px;
}

#form {
  text-align: center;
}

#form input {
  padding: 10px;
  margin: 10px;
  width: 400px;
  outline: none;
}

#about-us {
  margin-top: 10vh;
  margin-bottom: 10vh;
}
.container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.about-inner {
  height: 500px;
  width: 600px;
  padding: 20px;
}

.about-inner h2 {
  line-height: 1.2;
  font-size: 2em;
  color: #1c63b8;
  margin-bottom: 20px;
}

.about-inner p {
  line-height: 1.8;
  font-size: 1rem;
  margin-bottom: 30px;
}

.about-list {
  margin-bottom: 0px;
  list-style: none;
  font-weight: bolder;
  line-height: 3;
}

.about-list a {
  color: #1c63b8;
}

#services {
  background: url("https://i.imgur.com/15M508G.jpg") no-repeat center
    center/cover fixed;
}

.services-inner {
  background: #054694e7;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  line-height: 2;
  padding: 30px;
}

.services-inner h1 {
  font-size: 2em;
}

.boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 30px;
  margin: 0 100px;
}

.box {
  height: 200px;
  width: 300px;
  background: #429ffd;
  margin: 20px;
  color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
  padding: 20px;
  font-size: 0.9rem;
  line-height: 2;
}

.box h3 {
  font-size: large;
  margin-bottom: 10px;
}
.box p {
  margin-bottom: 10px;
}

.box:hover {
  background: #fff;
  color: #333;
}

.banner-inner {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin: 30px;
  line-height: 2;
  text-align: center;
}

.choose-us-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.whychoose-inner {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding-top: 50px;
  padding-bottom: 50px;
  background: #429ffd;
  color: #fff;
}

.whychoose-inner p {
  max-width: 600px;
}

.wrapper {
  margin: auto auto;
}

.choose {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}

.choose i {
  position: relative;
  top: 0px;
  right: 60px;
  background: #1c63b8;
  width: 50px;

  font-size: 1.5em;
  text-align: center;
  height: 50px;
  padding: 12px;
  border-radius: 50%;
}

.whychoose-img {
  height: 798px;
  width: 50%;
  background: url("https://i.imgur.com/7CQEsTS.jpg") no-repeat center
    center/cover fixed;
}

.dark-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
}

.primary-overlay {
  background-color: #054694e7;
  height: 100%;
}

#testimonials {
  height: 50vh;
  background: url("https://i.imgur.com/15M508G.jpg") no-repeat center
    center/cover fixed;
}

.testimonials-container {
  max-width: 950px;
  margin: auto auto;
}

.testimonials-inner {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  text-align: center;
}

.ourteam-header {
  text-align: center;
  margin-top: 50px;
  padding: 0 20px;
  line-height: 1.5;
}

.team {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: 50px;
  margin-bottom: 20px;
}

.team-img,
.team-img1,
.team-img2,
.team-img3 {
  height: 200px;
  width: 200px;
  margin: 20px;
  border-radius: 50%;
}

.person {
  height: 200px;
  width: 200px;
  border: 2px solid #1c63b8;
  border-radius: 50%;
}

.teambody {
  text-align: center;
  line-height: 2;
}

.form {
  margin: 0;
}

.contact-inner {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.form {
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

#contact-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#contact-form input {
  padding: 10px;
  margin: 20px;
  width: 400px;
  outline: none;
  border-radius: 5px;
  border: 1px solid #eee;
}


.contact-boxes {
  display: flex;
  flex-direction: column;
  height: 620px;
  width: 400px;
  align-items: center;
  padding: 0px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  margin-top: 20px;
  border-radius: 10px;
}

.contact-box {
  width: 360px;
  height: 150px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  align-items: center;
  display: flex;
  color: #fff;
  background: #1c63b8;
  margin: 10px;
  border-radius: 10px;
}

.box-inner {
  margin: auto auto;
  line-height: 1.5;
}

.google-maps {
  height: 400px;
  width: 100%;
  margin-top: 20px;
}

.footer-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  text-align: center;
  margin-top: 0px;
  background: #1c63b8;
  color: #fff;
  clear: both;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  body {
    overflow-x: hidden;
  }

  .hero-content {
    text-align: center;
    padding-left: 0;
  }
  .hero-content h1 {
    font-size: 2rem;
  }

  .hero-content p {
    font-size: smaller;
  }

  .about-video {
    display: none;
  }
  .about-inner {
    text-align: center;
    height: 600px;
  }

  .box {
    min-width: 300px;
  }

  .btn {
    margin-right: 0;
  }

  .whychoose-img {
    display: none;
  }

  .whychoose-inner {
    width: 100%;
    text-align: center;
  }
  .choose i {
    position: static;
    margin: auto auto;
    margin-bottom: 10px;
  }

  .team {
    margin-left: 0;
  }

  .testimonials-inner p {
    text-align: center;
  }

  #contact-form input textarea {
    width: 200px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .whychoose-img {
    display: none;
  }

  .whychoose-inner {
    width: 100%;
    text-align: center;
  }

  .choose i {
    position: static;
    margin: auto auto;
    margin-bottom: 10px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .whychoose-img {
    display: inline-block;
    height: 900px;
  }
  .whychoose-inner {
    width: 50%;
    height: 900px;
  }
}
</style>

<style lang="scss" scoped>
/* CODE ROY */

body {
  background: #21458d;
}
.btn__landing {
  border: 1px solid;
  border-radius: 17px;
  font-size: 15px !important;
  font-weight: 500;
  padding: 5px 15px;
  margin: 10px 15px !important;
  min-width: 130px;
  text-align: center;
  font-weight: bold;
  background: #fff;
  color: #4b92fc !important;
  border-color: #4b92fc;
}
.btn__landing:hover {
  background: #4b92fc;
  color: #fff !important;
  border-color: #4b92fc !important;
}

.title__resuelve {
  color: rgb(41, 79, 146);
  font-size: 18px;
  font-weight: 600;
  padding: 10px 15px;
}

.container__landing {
  padding-top: 0px;
  height: auto;
  background: #fff;
}
.container__landing img {
  margin: 0 auto !important;
}

ul {
  list-style: none;
}

ul li {
  /* background: #888; */
  color: #000;
  text-decoration: none;
  position: relative;
  font-size: 16px;
}

ul li::before {
  content: "";
  position: absolute;
  border-color: #4b92fc;
  border-style: solid;
  border-width: 0 2px 2px 0;
  top: 0px;
  left: -20px;
  transform: rotate(45deg);
  height: 15px;
  width: 7px;
}

.btn__registrate__landing {
  display: block;
  position: fixed;
  bottom: 0px;
  width: 100%;
  border-radius: 0px;
  background: #4b92fc !important;
  font-size: 20px;
  letter-spacing: 1px;
}

@media (max-width: 768px) {
  #header-img {
    width: 90px;
  }
  .btn__landing {
    padding: 5px 9px !important;
    font-size: 16px !important;
  }
  .section__footer {
    /*margin-bottom: 50px;*/
    background-color: red;
  }
}

/* END CODE ROY */

// LANDIN NEW

.container__item {
  background: transparent;
  padding: 0 15px 0 15px;
  //background-color: red;
  width: 20%;
  text-align: center;
  display: inline-block;
}

.container__item p {
  margin-top: 10px;
  font-size: 1em;
}
.container__img {
  background: #22468c;
  height: 5em;
  width: 5em;
  border-radius: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.container__img img {
  width: 100%;
}

.negrita__span {
  font-weight: bold;
  color: #000;
}

// SECCION BLACK

.section__black {
  position: relative;
  background: #000;
  color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
}

.section__black a {
  color: #fff;
  text-decoration: none;
}
.section__black .circle__img {
  width: 150px;
  height: 150px;
  position: absolute;
  right: 10px;
  top: -41px;
  border-radius: 100%;
}
.section__black .circle__img img {
  width: 100%;
}
.section__footer {
  text-align: center;
  background: #fff;
  color: #000;
  padding-top: 30px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 992px) {
  .container__item {
    width: 30%;
    height: 200px;
  }

  .container__img {
    height: 80px;
    width: 80px;
  }
}

@media only screen and (max-width: 670px) {
  .container__item {
    width: 50%;
    height: 140px;
  }

  .banner-img {
    height: 35vh !important;
  }

  .container__img {
    height: 60px;
    width: 60px;
  }

  .section__temainteres {
    margin-top: 1.2em;
  }
  #header a {
    display: flex;
    flex-direction: column;
    width: 25% !important;
  }

  #header a > span {
    font-size: 0.8em !important;
    display: none;
  }
  #header a > span > span {
    display: none;
  }

  #header-img {
    width: 5em;
  }

  #nav-bar {
    display: flex;
    flex-direction: row;
    width: 80%;
  }
  #nav-bar > div {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    margin-left: 0.9em !important;
  }
  .btn__landing__mobile {
    width: 0.7em !important;
    font-size: 0.8em !important;
    padding: 0.4em 0em 0.4em 0em !important;
    margin: 0em 0.5em !important;
  }

  .container-links {
    display: flex;
    flex-wrap: wrap;
  }

  .redes-sociales{
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 396px) {
    #nav-bar > div {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    margin-left: 1.4em !important;
  }
    .btn__landing__mobile {
    width: 0.3em !important;
    font-size: 0.7em !important;
    padding: 0.5em 0.4em 0.5em 0.4em !important;
    margin: 0em 0.3em !important;
    min-width: 100px;
  }
}

@media only screen and (min-width: 1600px) {
  .section__temainteres {
    padding: 2.7vh 0 2.7vh 0;
  }
}


.contenedor{
  position:fixed;
  right:1em;
  bottom:2em;
    z-index:1000;
}
.alicia-chatbot{
  width:8em;
  height:8em;
  border-radius:100%;
  cursor:pointer;
  box-shadow: 0 3px 10px rgba(0,0,0,0.16), 0 3px 10px rgba(0,0,0,0.23);
}

.alicia-chatbot:hover{
  width:8.1em;
  height:8.1em;
}

  .btn-el-comercio {
    text-decoration: none;
    padding: 7.5px 10px;
    font-weight: 600;
    font-size: 1em;
    color: #ffffff;
    background-color: rgb(0, 38, 230);
    border-radius: 6px;
    border: 2px solid #0016b0;
  }
  .btn-el-comercio:hover {
    background-color: rgb(64, 204, 255);
    border: 2px solid rgb(64, 204, 255);
  }

  .btn__actualizar {
    background: #4b92fc !important;
    border-color: #4b92fc !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    letter-spacing: 1px;
  }




</style>

<template>
  <div>
    <header id="header">
      <a href="javascript:void(0);">
        <img src="../../assets/img/logo_negro.png" id="header-img" />
        <span style="color:#000;font-size:1.3em;font-weight:normal;"
          ><span>|</span> Canal de autogestión digital</span
        >
      </a>
      <nav id="nav-bar">
        <ul class="mb-0">
          <router-link class="nav-link btn__landing" to="/login"
            >INICIAR SESIÓN</router-link
          >
          <router-link class="nav-link btn__landing" to="/register"
            >REGISTRATE</router-link
          >
        </ul>
        <div>
          <router-link
            class="nav-link btn__landing btn__landing__mobile d-none-block d-sm-block d-md-none d-lg-none"
            to="/login"
            >Ingresar</router-link
          >
          <router-link
            class="nav-link btn__landing btn__landing__mobile d-none-block d-sm-block d-md-none d-lg-none"
            to="/register"
            >Registrate</router-link
          >
        </div>
      </nav>
    </header>
<!--
<div class="contenedor" @click="$bvModal.show('modal-xl')">
  <img class="alicia-chatbot" src="../../assets/img/alicia-chatbot.png" alt=""  >
 </div>
-->
  <b-modal id="modal-xl" size="xl" hide-footer no-close-on-backdrop hide-header-close no-close-on-esc>
    <template #modal-title>
      Acceso a los formularios para resolver tus inconvenientes
    </template>
    <div style="padding:0.5em 2em 1.5em 2em;">
        Actualmente me encuentro en mantenimiento, pero puedes dejar tus consultas en los siguientes formularios 😀!.
          <br>
          <br>
          <div style="display:flex;flex-wrap:wrap;justify-content:space-around;">
              <router-link class="btn-el-comercio btn__actualizar" to="/incidencia-reparto" target= '_blank' style="margin-top:0.5em;"
              >Incidencia de reparto</router-link
              >
              <router-link class="btn-el-comercio btn__actualizar" to="/cambio-direccion" target= '_blank' style="margin-top:0.5em;"
                >Cambio de dirección</router-link
              >

              <router-link class="btn-el-comercio btn__actualizar" to="/otras-consultas-solicitudes" target= '_blank' style="margin-top:0.5em;"
                >Otras consultas y solicitudes</router-link
              >
          </div>
      </div>
    <b-button class="mt-3" block @click="$bvModal.hide('modal-xl') + showImg()"  style="padding: 7px;
    border-radius: 3px;
">Cerrar</b-button>
  </b-modal>


    <section>
      <div class="container-fluid container__landing">
        <div class="row">
          <img
            src="../../assets/img/baner.png"
            class="banner-img d-none d-sm-block"
            style="width: 100%;height:58vh;"
          />
          <img
            src="../../assets/img/oficina_baner_oficial.jpg"
            class="banner-img d-sm-none d-md-none d-lg-none d-xl-none"
            style="width: 100%;height:67vh;"
          />
        </div>
      </div>
    </section>
    <section class="section__temainteres">
      <div class="container-fluid py-3" style="background: #fff">
        <div class="row">
          <div class="col-12" style="text-align: center;margin-top:1.5vh;">
            <p
              style="font-size: 1.4em;font-weight: bold;color: #000;font-family:'RobotoBlack'"
            >
              Selecciona el tema de interés:
            </p>
          </div>
          <div class="col-12" style="text-align:center;">
            <div class="container__item">
              <div class="container__img">
                <a href="http://bit.ly/3nQDdpg" target="_blank"
                  ><img src="../../assets/img/icon_landing1.jpeg"
                /></a>
              </div>
              <p>
                Adquirir una <br />
                <span class="negrita__span"> suscripción</span>
              </p>
            </div>
            <div class="container__item">
              <div class="container__img">
                <a href="https://clubelcomercio.pe/" target="_blank"
                  ><img src="../../assets/img/icon_landing1.jpeg"
                /></a>
              </div>
              <p>
                <span class="negrita__span"> Beneficios </span> del <br />
                Club El Comercio
              </p>
            </div>
            <div class="container__item">
              <div class="container__img">
                <a href="/#/login" target="_blank"
                  ><img src="../../assets/img/icon_landing2.jpeg"
                /></a>
              </div>
              <p>
                Uso y administración <br />
                de <span class="negrita__span"> mi cuenta</span>
              </p>
            </div>
            <div class="container__item">
              <div class="container__img">
                <a href="/#/login" target="_blank"
                  ><img src="../../assets/img/icon_landing3.jpeg"
                /></a>
              </div>
              <p>
                <span class="negrita__span"> Información,</span> <br />
                solicitudes y reclamos
              </p>
            </div>
            <div class="container__item">
              <div class="container__img">
                <a
                  href="https://terminosycondicioneclubelcomercio.s3.amazonaws.com/T%C3%89RMINOS+Y+CONDICIONES+DEL+CHATBOT+DE+LAS+SUSCRIPCIONES+DE+EL+COMERCIO.pdf"
                  target="_blank"
                  ><img src="../../assets/img/icon_landing4.jpeg"
                /></a>
              </div>
              <p>
                <span class="negrita__span"> Términos </span> y <br />
                condiciones
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section__black">
      <div class="container-fluid">
        <div class="row container-links">
          <div class="col-6 col-sm-2 offset-sm-2">
            <a
              style="color:rgba(255,255,255,0.9);"
              href="https://terminosycondicioneclubelcomercio.s3.amazonaws.com/T%C3%89RMINOS+Y+CONDICIONES+DEL+CHATBOT+DE+LAS+SUSCRIPCIONES+DE+EL+COMERCIO.pdf"
              target="_blank"
            >
              Términos y condiciones
            </a>
          </div>
          <div class="col-6 col-sm-2">
            <a
              style="color:rgba(255,255,255,0.9);"
              href="https://terminosycondicioneclubelcomercio.s3.amazonaws.com/T%C3%89RMINOS+Y+CONDICIONES+DEL+CHATBOT+DE+LAS+SUSCRIPCIONES+DE+EL+COMERCIO.pdf"
              target="_blank"
            >
              Preguntas frecuentes
            </a>
          </div>
          <div class="col-6 col-sm-2 ">
            <a
              style="color:rgba(255,255,255,0.9);"
              href="http://ecomedia.pe/libro/inicio/elcomercio/"
              target="_blank"
            >
              Libro de reclamaciones</a
            >
          </div>
          <div class="col-6 col-sm-2 ">
            <a
              style="color:rgba(255,255,255,0.9);"
              href="https://clubelcomercio.pe/establecimiento"
              target="_blank"
              >Acceso a establecimientos
            </a>
          </div>
        </div>
      </div>
    </section>

    <section class="section__footer">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-1 offset-md-1 col-6 mb-3">
            <a href="https://elcomercio.pe/" target="_blank"
              ><img
                style="height:1.5em;width:8em;"
                src="../../assets/img/elcomercio_original.png"
            /></a>
          </div>
          <div class="col-md-1 col-6 mb-3">
            <a href="https://gestion.pe/" target="_blank"
              ><img
                style="height:1.5em;width:8em;"
                src="../../assets/img/logo_gestion.png"
            /></a>
          </div>
          <div
            class="col-md-5 col-12 mb-2"
            style="color:rgba(60,75,100,0.6);font-size:0.9em;font-family:RobotoBoldCondensed;"
          >
            TODOS LOS DERECHOS RESERVADOS © 2021 Club El Comercio
          </div>
          <div
            class="col-md-1 col-12 mb-2 redes-sociales"
            style="display:flex;color:rgba(60,75,100,0.8);font-size:1em;font-weight:bold;font-family:RobotoBoldCondensed;"
          >
            <span style="font-size:0.9em;margin-top:3px;">CONTÁCTANOS</span>
            <a
              style="margin-left:0.5em;"
              href="https://www.facebook.com/ClubElComercioPeru"
              target="_blank"
              ><CIcon style="color:rgba(60,75,100,0.6);" name="cibFacebook"
            /></a>
            <a
              href="https://www.instagram.com/clubelcomercio.pe/"
              style="margin-left:0.5em;"
              target="_blank"
              ><CIcon style="color:rgba(60,75,100,0.6);" name="cibInstagram"
            /></a>
            <a
              target="_blank"
              href="https://wa.link/hr6sbz"
              style="margin-left:0.5em;"
              ><CIcon name="cibWhatsapp" style="color:rgba(60,75,100,0.6);"
            /></a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script src="https://cdn.freecodecamp.org/testable-projects-fcc/v1/bundle.js"></script>
<script>
export default {
  data: () => ({}),
  methods: {
    redirectRegister() {
      this.$router.push("/register");
    },
    redirectIngresar() {
      this.$router.push("/login");
    },

    goRegister() {
      localStorage.setItem("sesion-appweb-comercio", "0");
      this.$router.push("/register");
    },
  },
  mounted() {
    const navbarToggler = document.querySelector(".navbar-toggler");
    const navbarMenu = document.querySelector("#nav-bar ul");
    const navLinks = document.querySelectorAll("#nav-bar a");
    const nav = document.querySelector("#nav-bar");

    navLinks.forEach((elem) => elem.addEventListener("click", navbarLinkClick));

    function navbarLinkClick() {
      if (navbarMenu.classList.contains("open")) {
        navbarToggler.click();
      }
    }
    console.log("Landing");
    $("#pgb-container").show();
    setTimeout(() => {
      $("#pgb-container").show();
    }, 5000);
  },
};
</script>
